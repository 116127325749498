.tagify.form-custom-input-tagify{
    border-radius: 6px!important;
    border: 1px solid var(--bs-gray-300);
    padding: 5px 4px 5px 11px;
    min-height: 45px!important;
    z-index: 0;
    & > .tagify__tag{
            border-radius: 15px!important;
            background-color: #616161;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
            height: 30px;
        &>div::before{
            opacity: 0;
            background-color: #616161;
        }
        & .tagify__tag-text{
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
        }
    }
    & .tagify__tag .tagify__tag__removeBtn{
        background-color: #FFF;
        width: 8px;
        height: 8px;
        margin: 0 5px 0 0;
    }
}

[data-bs-theme=dark] .form-custom-input-tagify {
    background-color: var(--bs-gray-400) !important;
    border-color: var(--bs-gray-400) !important;
    color: var(--bs-gray-900) !important;
    .react-select__single-value{
        color: var(--bs-gray-900) !important;
    }
}