.btn.btn-primary-mc{
	.icon-loading{
		display: none;
	}
	&.btn-loading{
		color: transparent!important;
		& > .icon-loading{
			display: block;
		}
	}
}
[data-bs-theme=dark] {
	// .btn.btn-primary-mc{
	// 	.icon-loading.mc-icon-spinner-ios > div{
	// 		background-color: #242939!important;
	// 	}
	// }
}