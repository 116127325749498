.table-background-red{
  & thead{
    background: #FFEDED;
  }
  & .table-responsive{
    border-radius: var(--bs-card-border-radius);
  }
}
.badge-light-dark-custom{
  border-radius: 40px;
  border: 1px solid #CDCDCD;
  background: #FFF;
  align-items: center;
}
[data-bs-theme=dark] {
  .badge-light-dark-custom{
    color: #3b3b58;
  }
  .table-background-red{
    & thead{
      background: #565674;
    }
  }
}