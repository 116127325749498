.noti-modal{
    .modal-content{
        border-radius: 0.425rem;
        background: #FFF;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.10);
        &:not(.shadow-none){
            margin: 0 30px;
        }
    }
    .modal-body{
        padding: 20px;
    }
    .modal-content-text{
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        margin-bottom: 15px;
    }
}
[data-bs-theme=dark] {
    .noti-modal{
        .modal-content{
            background: #565674;
        }
    }
}