.react-select-container{
	margin-left: 1px;
	.react-select__control.react-select__control--is-focused{
		border-color: var(--bs-gray-400);
		outline: 0;
		box-shadow: false, 0 0 0 0.25rem rgba(var(--bs-component-active-bg), 0.25);
	}
	& .react-select__control {
		border: 1px solid var(--bs-gray-300);
        appearance: none;
        border-radius: 0.475rem;
		padding: 3px 4px 3px 4px;
		& .react-select__single-value{
			color: #111;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: -0.15px;
		}
		& .react-select__multi-value{
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
			& .react-select__multi-value__label{
				font-size: 13px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 4px 0px 4px 10px;
			}
			& .react-select__multi-value__remove{
				font-size: 13px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 0px 3px 0px 0px;
				 & svg{
					width: 19px;
    				height: 19px;
				 }
			}
			
		}
		& .react-select__clear-indicator{
			display: none;
		}
		& .react-select__indicator-separator{
			display: none;
		}
		& .react-select__dropdown-indicator{
			padding: 14px 10px 13px 10px;
		}
	}
	
}
[data-bs-theme=dark] {
	.react-select-container{
		& .react-select__control {
			background-color: var(--bs-gray-400) !important;
    		border-color: var(--bs-gray-400) !important;
			& .react-select__single-value{
				color: var(--bs-gray-900) !important;
			}
		}
		& .react-select__menu-list{
			color: #111;
		}
	}
}