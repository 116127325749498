// Customization
.form-control {
  // Transparent style
   &.form-control-border-mc{
    border: 1px solid #CDCDCD;
    background: #FFF;    
  }
  &.form-control-radius-mc{
    border-radius: 15px;   
  }
}
.form-custom-check{
	> .form-check-input{
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background-color: #E7E7E7;
		border-width: 4px;
		border-color: transparent;
		--bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23A9A9A9%27/%3e%3c/svg%3e);
		&:checked[type=radio] {
			--bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23ffffff%27/%3e%3c/svg%3e);
			background-color: #2F37ED;
			border-color: transparent;
		}
	}
	> .form-custom-check-label{
		color: #111;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.15px;
		margin-left: 12px;
	}
}

[data-bs-theme=dark] {
	.form-custom-check{
		> .form-custom-check-label{
			color: #fff;
		}
	}
	.form-control{
		&.form-control-border-mc{
			background: #e7e7e7;
		}
	}
}